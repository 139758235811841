/* eslint-disable */
<template>
  <v-app>
    <section class="mt-10">
      <v-container>
        <v-container>
          <v-row>
            <v-col lg="9" md="8" cols="12">
              <div class="bd-example">
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe
                    class="embed-responsive-item"
                    src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                    allowfullscreen=""
                  />
                </div>
              </div>
            </v-col>
            <v-col lg="3" md="4" cols="12">
              <h1 class="heading-2">
                {{ item.name }}
              </h1>
              <h1 class="heading-1">
                {{ item.name }}
              </h1>

              <span
                class="product-price body-2"
                style="margin-top: 5px; margin-right: 1px"
                >$</span
              ><span class="body-3">79</span
              ><span
                class="product-price body-2"
                style="margin-left: 2px; margin-top: 3px"
                >00</span
              >
              <br />
              <span>Availability:</span>
              <span style="font-weight: bold">Available</span>

              <br />
              <span>Vendor:</span>
              <span style="font-weight: bold">Vuetify</span>
              <br />
              <v-btn
                color="primary mt-3"
                style="
                  font-size: 0.875rem;
                  font-weight: 500;
                  letter-spacing: 0.0892857143em;
                "
                width="100%"
              >
                Xудалдаж аваx
              </v-btn>
            </v-col>
          </v-row>
          <v-card class="mt-10">
            <v-card-text class="">
              <h1 class="heading-1">
                {{ item.name }}
              </h1>
              <div style="font-size: 15px">
                <div
                  style="
                    margin-right: 15px;
                    margin-top: 5px;
                    display: inline-block;
                  "
                >
                  <div>
                    <span class="best_seller"> MOST POPULAR</span>
                  </div>
                </div>
                <i
                  class="fa fa-star"
                  aria-hidden="true"
                  style="font-size: 20px; color: yellow; margin-right: 4px"
                /><i
                  class="fa fa-star"
                  aria-hidden="true"
                  style="font-size: 20px; color: #ffde16; margin-right: 4px"
                /><i
                  class="fa fa-star"
                  aria-hidden="true"
                  style="font-size: 20px; color: #ffde16; margin-right: 4px"
                /><i
                  class="fa fa-star"
                  aria-hidden="true"
                  style="font-size: 20px; color: #ffde16; margin-right: 4px"
                /><i
                  class="fa fa-star-half-o"
                  aria-hidden="true"
                  style="font-size: 20px; color: #ffde16; margin-right: 4px"
                />
                4.5 (289 ratings)
              </div>
              <p v-if="item.description" class="mt-8">
                {{ item.description }}
              </p>
            </v-card-text>
            <v-card-title class="pt-0"> Quick Facts </v-card-title>
            <v-card-text class="ml-5">
              <ul>
                <li>
                  <span style="color: #2d76cd" data-mce-style="color: #2d76cd;">
                    <strong>Reliable &amp; regular updates.</strong></span
                  ><span
                    >&nbsp;This theme is maintained by the author of Test Test,
                    John Leider.<br
                  /></span>
                </li>
                <li>
                  <span style="color: #2d76cd">
                    <strong>Professionally developed.</strong></span
                  ><span
                    >&nbsp;All components and features are developed using the
                    most up-to-date coding practices.<br
                  /></span>
                </li>
                <li>
                  <span style="color: #2d76cd">
                    <strong>Blazing fast &amp; optimized.</strong></span
                  ><span
                    >&nbsp;Themes are designed for maximum performance and are
                    semantically structured to maximize SEO.<br
                  /></span>
                </li>
                <li>
                  <span style="color: #2d76cd">
                    <strong>Built on Test Test.</strong></span
                  >&nbsp;Being developed with Test Test means you have access to
                  all of the frameworks available features.
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-container>
      </v-container>
    </section>
  </v-app>
</template>
<script>
const fb = require("../../firebaseConfig.js");
export default {
  props: {
    asuulgaId: null,
  },
  data: () => ({
    item: null,
  }),
  created() {
    console.log(this.asuulgaId);
    fb.db
      .collection("asuulga")
      .doc(this.asuulgaId)
      .get()
      .then((itemDoc) => {
        this.item = itemDoc.data();
        this.item.ref = itemDoc.ref;
        this.item.id = itemDoc.id;
      });
  },
  methods: {
    _goDetail() {
      this.$router.push({
        name: "YQuesionaryCardDetail",
        params: { asuulgaId: this.asuulga.id },
      });
    },
  },
};
</script>
<style>
.heading-2 {
  font-size: 2.125rem !important;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
  font-family: "Roboto", sans-serif !important;
}

.heading-1 {
  font-size: 2.125rem !important;
  line-height: 2.5rem;
  letter-spacing: 0.00735294em !important;
  font-family: "Roboto", sans-serif;
}
.body-2 {
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing: 0.0178571429em !important;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif !important;
}
.body-3 {
  font-size: 2.125rem !important;
  font-weight: bold;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
  font-family: "Roboto", sans-serif !important;
}
</style>

<script>
export default {
  props: {
    asuulga: null,
  },
  data: () => ({
    item: {
      color: "#952175",
      src: "https://cdn.vuetifyjs.com/images/cards/halcyon.png",
      title: "Halcyon Days",
      artist: "Ellie Goulding",
    },
    rating: 3,
  }),
  methods: {
    _goDetail() {
      console.log("xxx");
      this.$router.push({
        name: "YQuesionaryCardDetail",
        params: { asuulgaId: this.asuulga.id },
      });
    },
  },
};
</script>